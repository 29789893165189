/** @jsx jsx */
import React, { useState, useMemo } from "react";
import { graphql } from "gatsby";
import { jsx, Container, Box } from 'theme-ui';
import Layout from '../components/Layout';
import CardWrapper from "../components/shared/CardWrapper";
import Pagination from "../components/shared/pagination/Pagination";
import HeroSection from "../components/sections/heroSection";
import SEO from "../components/seo";
import SearchComponent from "../components/shared/SearchComponent";

export const pageQuery = graphql`
  query blogIndex {
    allContentfulPage(filter: {slug: {in: ["blog", "blog-mdx"]}}) {
      nodes {
        id
        seoMetadata {
          description
          keywords
          ogImage {
            gatsbyImageData
            title
          }
          title
        }
        sections {
          ... on ContentfulHeroSection {
            ...ContentfulHeroSectionFragment
          }
        }
        bannerData {
          bannerImage {
            gatsbyImageData(width:30)
          }
          bannerSlug
          bannerText {
            raw
          }
          label
          link
        }
      }
    }
    allMdx(
      filter: {fileAbsolutePath: {regex: "/content/blog/"}}
      sort: {fields: frontmatter___date, order: ASC}
    ) {
      edges {
        node {
          id
          slug
          excerpt
          frontmatter {
            title
            author
            date(formatString: "MMMM DD, YYYY")
            featuredImage {
              childImageSharp {
                gatsbyImageData(width:372)
              }
            }
          }
        }
      }
      totalCount
    }
    allContentfulBlogPost(
      filter: {
        postType: {in: ["blog", "blog-mdx"]},
        isHidden: {ne: true}
      }
      sort: {fields: date, order: DESC}
    ) {
      edges {
        node {
          id
          slug
          title
          author
          authorReference {
            name
          }
          description
          date(formatString: "MMMM DD, YYYY")
          featuredImage {
            gatsbyImageData(width:372)
          }
          featuredImageAltText
        }
      }
      totalCount
    }
    file(relativePath: {eq: "background-pattern.png"}) {
      id
      childImageSharp {
        gatsbyImageData(
          width: 2000,
        )
      }
    }
  }
`;

const BlogIndex = ({ data }) => {
  const { sections, seoMetadata, bannerData} = data.allContentfulPage.nodes && data.allContentfulPage.nodes.length > 0 ? data.allContentfulPage.nodes[0] : [];
  const allPostsSorted = [...data.allContentfulBlogPost.edges].sort((a, b) => {
    const aTimestamp = new Date(a.node.date || a.node?.frontmatter?.date).getTime();
    const bTimestamp = new Date(b.node.date || b.node?.frontmatter?.date).getTime();
    return bTimestamp - aTimestamp;
  });
  
  const pageNumberLimit = 9;
  const paginationLimit = 6;
  const [currentPage, setCurrentPage] = useState(1);
  const [maxPageLimit, setMaxPageLimit] = useState(5);
  const [minPageLimit, setMinPageLimit] = useState(0);

  const onPageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const onPrevClick = () => {
    if ((currentPage - 1) <= minPageLimit) {
      setMaxPageLimit(maxPageLimit - (paginationLimit - 1));
      setMinPageLimit(minPageLimit - (paginationLimit - 1));
    }

    setCurrentPage(prev => prev - 1);
  };

  const onNextClick = () => {
    if (currentPage + 1 > maxPageLimit) {
      setMaxPageLimit(maxPageLimit + (paginationLimit - 1));
      setMinPageLimit(minPageLimit + (paginationLimit - 1));
    }

    setCurrentPage(prev => prev + 1);
  };

  const paginatedPosts = useMemo(() => {
    const posts = allPostsSorted.slice(((currentPage - 1) * pageNumberLimit), ((currentPage - 1) * pageNumberLimit) + pageNumberLimit);
    return posts;
  }, [allPostsSorted, currentPage, pageNumberLimit]);


  const paginationAttributes = {
    currentPage,
    maxPageLimit,
    minPageLimit,
    totalPages: Math.ceil((data.allContentfulBlogPost.totalCount) / pageNumberLimit)
  };

  return (
    <Layout bannerData={bannerData}>
      {seoMetadata && (
        <SEO
          title={seoMetadata.title}
          description={seoMetadata.description}
        />
      )}
      {sections && sections.length > 0  && <Box>
        <HeroSection sectionContent={sections[0]} />
      </Box>}
      <Box sx={{ position: 'relative', pt: [4], backgroundColor: "#F4F4F7", pb: [4] }}>
        <Container>
          <Box sx={{
            display: 'flex',
            rowGap: "30px",
            listStyle: 'none',
            flexWrap: 'wrap',
            justifyContent: ['center', null, 'space-between'],
            alignItems: 'center',
            padding: 0,
            my: [2, 4],
            '&::after': {
              content: '""',
              flex: ['0 1 96%', null, '0 1 48%', null, '0 1 31%']
            }
          }}>
            {paginatedPosts.map(({ node: post }) => (
              <Box
                key={post.id}
                sx={{
                  flex: ['0 1 96%', null, '0 1 48%', null, '0 1 31%'],
                  display: 'flex',
                  margin: '6px 0',
                }}
              >
                <CardWrapper
                  item={{
                    heading: post?.frontmatter?.title || post?.title,
                    image: post?.frontmatter?.featuredImage?.childImageSharp || post?.featuredImage,
                    imageAltText: post?.frontmatter?.title || post?.featuredImageAltText,
                    linkUrl: `/blog/${post.slug}`,
                    date: post?.frontmatter?.date || post?.date,
                    author: post?.authorReference?.name || post?.author || `Great Expectations`,
                    description: post?.frontmatter?.description || post?.description,
                    cardHeight: '520px',
                  }}
                />

              </Box>
            ))}
          </Box>
          <Pagination
            {...paginationAttributes}
            onPrevClick={onPrevClick}
            onNextClick={onNextClick}
            onPageChange={onPageChange} />
        </Container>
        <SearchComponent dataFor="blog"/>
      </Box>
      {sections && sections.length > 1  && <HeroSection sectionContent={sections[1]} />}
    </Layout>
  );
};

export default BlogIndex;