import React from 'react';
import "./pagination.css";
import { IconButton } from 'theme-ui';
import Icon from '../Icon';

const Pagination = (props) => {

  const { currentPage, maxPageLimit, minPageLimit, totalPages, onPrevClick, onNextClick, onPageChange } = props;
  const pages = [...Array(totalPages).keys()].map(i => i + 1);

  const handlePrevClick = () => {
    onPrevClick();
  };

  const handleNextClick = () => {
    onNextClick();
  };

  const handlePageClick = (e) => {
    onPageChange(Number(e.target.id));
  };

  const pageNumbers = pages.map(page => {

    if (page <= maxPageLimit && page > minPageLimit) {
      return (
        // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/click-events-have-key-events
        <li key={page} id={page} onClick={handlePageClick}
          className={currentPage === page ? 'active' : null}>
          {page}
        </li>
      );
    }

    return null;
  });

  return (
    <ul className="pageNumbers">
      {currentPage !== pages[0] && <li>
        <IconButton
          onClick={handlePrevClick}
          className='iconbutton'
        >
          <Icon icon='left-chevron' size='15' />
        </IconButton>
      </li>}
      {pageNumbers}
      {currentPage !== pages[pages.length - 1] && <li>
        <IconButton
          onClick={handleNextClick}
          className='iconbutton'
        >
          <Icon icon='right-chevron' size='15' />
        </IconButton>
      </li>}
    </ul>
  );
};

export default Pagination;
